import React, { useEffect } from "react"

const RedirectPage = () => {
  useEffect(() => {
    const today = new Date()
    const month = today.toLocaleString("default", { month: "short" })
    const day = today.getDate()
    const dateFormatted = `${month}-${day}`
    window.location.href = `/reading/${dateFormatted}/`
  }, [])

  return <noscript>If you haven't been redirected now, please enable JavaScript.</noscript>
}

export default RedirectPage